export default {
  data() {
    return {
      pageAll: 1,
      currentPageAll: 1,
      nextPageAll: null,
      totalPagesAll: 8,

      pageAddition: 1,
      currentPageAddition: 1,
      nextPageAddition: null,
      totalPagesAddition: 8,

      pageSubtraction: 1,
      currentPageSubtraction: 1,
      nextPageSubtraction: null,
      totalPagesSubtraction: 8,
    };
  },
  computed: {
    numberPagination() {
      return Math.ceil(this.transactions.points_records.length / this.totalPagesAll)
    },

    numberPaginationAddition() {
      return Math.ceil(
        this.transactions.points_records.filter(
          (point_record) => point_record.action === "addition"
        ).length / this.totalPagesAddition
      );
    },

    numberPaginationSubtraction() {
      return Math.ceil(
        this.transactions.points_records.filter(
          (point_record) => point_record.action === "subtraction"
        ).length / this.totalPagesSubtraction
      );
    },

    filterAll() {
      return this.transactions.points_records
        .slice().reverse() // Slice then reverse to avoid affecting original array (reverse mutates original but slice creates a new one)
        .slice(
          (this.pageAll - 1) * this.totalPagesAll,
          (this.pageAll - 1) * this.totalPagesAll + this.totalPagesAll
        );
    },
    

    filterSubtraction() {
      return this.transactions.points_records
        .slice().reverse() // Slice then reverse to avoid affecting original array (reverse mutates original but slice creates a new one)
        .filter((point_record) => point_record.action === "subtraction")
        .slice(
          (this.pageSubtraction - 1) * this.totalPagesSubtraction,
          (this.pageSubtraction - 1) * this.totalPagesSubtraction +
            this.totalPagesSubtraction
        );
    },

    filterAddition() {
      return this.transactions.points_records
        .slice().reverse() // Slice then reverse to avoid affecting original array (reverse mutates original but slice creates a new one)
        .filter((point_record) => point_record.action === "addition")
        .slice(
          (this.pageAddition - 1) * this.totalPagesAddition,
          (this.pageAddition - 1) * this.totalPagesAddition +
            this.totalPagesAddition
        );
    },

    
  },
};
